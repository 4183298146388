import React, { Fragment, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react';
import * as rax from 'retry-axios';
import { default as onfidoLogo } from '../../../assets/onfido_logo.svg';

import { StoreContext } from '../../App';
import { Modal } from '../../modal/component';
import { getApiOrigin } from '../../../methods/getApiOrigin';
import { axiosConfig } from '../../../constants/axiosConfig';
import { flowDefault, flowRemediation } from '../../../constants/clientConfigDefaults'
import { errors } from '../../../constants/errors';

export const UploadIdStart: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { translations } = store.AppConfig;
  const { showLoading } = store.AppConfig.onfidoConfig;
  const { sdkToken, onfidoNew } = store.ClientState;

  const { finishLaterLabel, continueLabel, pageUploadIdLoading } = translations;

  const {
    heading,
    pleaseUpload,
    learnMore,
    americanExpressDescription,
    submittingDocuments,
    redirected,
    modal,
    modalFinishLater,
    pleaseWait,
    pleaseWaitLonger,
  } = translations.pageUploadIdStart;

  const [loading, setLoading] = useState(true);
  const [laterModalVisible, setLaterModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [spinnerText, setSpinnerText] = useState(pleaseWait);

  const axiosInstance = axios.create();
  axiosInstance.defaults.raxConfig = {
    ...axiosConfig(axiosInstance),
    onRetryAttempt: (err): void => {
      const cfg = rax.getConfig(err);
      if (cfg.currentRetryAttempt === 5) {
        store.AppState.changePage('error', 1);
      }
    },
  };
  const interceptorId = rax.attach(axiosInstance);

  const getOnfidoToken = async (): Promise<string> => {
    try {
      const response = await axiosInstance.request({
        method: 'GET',
        url: `${getApiOrigin()}/onfido/token`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });

      return response.data.onfidoSdkToken;
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnfidoToken = async (): Promise<void> => {
    try {
      setLoading(false);
      const token = await getOnfidoToken();

      if (token && token.length) {
        store.ClientState.setSdkToken(token);
        setLoading(false);
      }
    } catch (e) {
      store.ClientState.flow === flowDefault
        ? store.AppState.setError(errors.errorOnfidoTokenAcq)
        : store.AppState.setError(errors.errorOnfidoTokenRem);
      store.AppState.changePage('error', 1);
      console.log(e);
    }
  };

  const changeSubpageDependsOnFlow = (): void => {
    if (store.ClientState.flow === flowRemediation) {
      store.AppState.changePage('uploadid', 2);
    }
  };

  useEffect(() => {
    changeSubpageDependsOnFlow();
    store.ClientState.flow === flowDefault ? handleOnfidoToken() : '';

    setTimeout(() => {
      setSpinnerText(pleaseWaitLonger);
      clearTimeout(Number(this));
    }, 5000);
  }, []);

  return (
    <Fragment>
      {showLoading && loading ? (
        <div className="page page--loading">
          <span className="loading-spinner" />
          <h1>{pageUploadIdLoading.idinComplete}</h1>
          <p className="subtitle">Onfido laden</p>
        </div>
      ) : (
        <>
          <div className="page page--upload-id">
            <h1 className="onfido-intro-heading">{heading}</h1>
            <p className="text">{pleaseUpload}</p>
            <div className="provider-logo">
              <div className="liner centered">
                <img src={onfidoLogo} />
              </div>
              <div className="liner centered">
                <a href="#" onClick={(): void => setInfoModalVisible(true)}>
                  {learnMore}
                </a>
              </div>
            </div>
            <p className="text">
              {americanExpressDescription}{' '}
              <a
                href="https://www.americanexpress.com/nl/informatie/voorwaarden/signicat/privacy-voorwaarden.html"
                target="_blank"
                rel="noreferrer"
              >
                hier.
              </a>
            </p>
            <p className="text">{submittingDocuments}</p>
            {onfidoNew && onfidoNew === '1' ? (
              <div className="info-box">
                <span className="icon-info" />
                <span className="text">
                  Let op: een rijbewijs accepteren wij{' '}
                  <b>
                    <u>niet</u>
                  </b>{' '}
                  als een geldig identiteitsbewijs. U kunt zich identificeren met een paspoort of ID-document uit een EER-land.
                </span>
              </div>
            ) : (
              ''
            )}
            {!sdkToken ? (
              <div className="onfido-token__holder">
                <div className="onfido-token-spinner" />
                <div className="redirect-info">{spinnerText}</div>
              </div>
            ) : (
              <div className="liner centered">
                <button className="btn btn-continue" onClick={(): void => store.AppState.changePage('uploadid', 2)}>
                  {continueLabel}
                </button>
                <div className="redirect-info">{redirected}</div>
              </div>
            )}
            <div className="liner centered">
              <a href="#" onClick={(): void => setLaterModalVisible(true)} className="finish-later">
                {finishLaterLabel}
              </a>
            </div>
            <Modal modalVisible={infoModalVisible} setModalVisible={setInfoModalVisible}>
              <div className="modal--onfido-info">
                <div className="line centered">
                  <img src={onfidoLogo} />
                  <p>{modal.text}</p>
                </div>
              </div>
            </Modal>
            <Modal modalVisible={laterModalVisible} setModalVisible={setLaterModalVisible}>
              <div className="modal--finish-later-onfido">
                <div className="line centered">
                  <h2>{modalFinishLater.heading}</h2>
                  <p>{modalFinishLater.paragraphFirst}</p>
                  <p>{modalFinishLater.paragraphSecond}</p>
                  <button className="btn btn-primary" onClick={(): void => setLaterModalVisible(false)}>
                    {modalFinishLater.buttonComplete}
                  </button>
                  <button className="btn btn--bordered" onClick={(): void => store.AppState.changePage('continue', 1)}>
                    {modalFinishLater.buttonLeave}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </>
      )}
    </Fragment>
  );
});
